import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HomeIcon from "@mui/icons-material/Home";
import ContactsIcon from "@mui/icons-material/Contacts";
import logoImg from "../media/logo.png";
import { Container } from "@mui/system";
import CustomButton from "./CustomButton";
import '../../src/index.css';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
const Navbar = () => {

   const [mobileMenu, setMobileMenu] = useState({
    left: false,
  });

  const toggleDrawer = (anchor,open) => (event) =>{
    if(
      event.type === "keydown" && 
      (event.type === "Tab" || event.type === "Shift"))
    {
      return;
    }
    setMobileMenu({ ...mobileMenu,[anchor]: open});
  };



  const list = (anchor) => (
    <Box sx={{
      width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      height: "100%",
      background: "#E6F0FF",
      color:"#000"
    }} 
      role="presentation"
      onClick={toggleDrawer(anchor,false)}
      onKeyDown = {toggleDrawer(anchor,false)}
      >
        <List>
          {["Home","Features","Services","Listed","Contact"].map(
            (text,index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index === 0 && <HomeIcon />}
                    {index === 1 && <FeaturedPlayListIcon />}
                    {index === 2 && <MiscellaneousServicesIcon />}
                    {index === 3 && <ListAltIcon />}
                    {index === 4 && <ContactsIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
    </Box>
  );


  const NavLink = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#4F5361",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "none",

    "&:hover": {
      color: "#0F1B4C",
      display: "block",
      backgroundColor: "#fff",
      borderColor: "#0F1B4C",
      padding: "0.5rem 1.25rem",
      borderRadius: "7px",
      border: "2px solid transparent",
    },
  }));
  const NavbarLinksBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));
  const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: "pointer",
    display: "none",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display:"block",
    },
  }));
  const NavbarContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    alignItems:"center",
    justifyContent:"space-between",
    padding: theme.spacing(5),
    [theme.breakpoints.down("md")]:{
      padding: theme.spacing(2),
    },
  }));
  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    [theme.breakpoints.down("md")]:{
      display: "none",
    },
  }));

  return <NavbarContainer>
    <Box 
      sx={{
        display:"flex",
        alignItems: "center",
        justifyContent:"center",
        gap: "2.5rem",
      }}
    >
      <Box 
      sx={{
        display:"flex",
        alignItems:"center",
      }}>
        <CustomMenuIcon onClick={toggleDrawer("left",true)} />
        <Drawer
          anchor="left"
          open={mobileMenu["left"]}
          onClose={toggleDrawer("left",false)}
        >
          {list("left")}
        </Drawer>
        <NavbarLogo src={logoImg} alt="logo" />
      </Box>
      <NavbarLinksBox>
        <Link className="linkRoute" to="/"><NavLink variant="body2">Home</NavLink></Link>
        <Link className="linkRoute" to="/login"><NavLink variant="body2">Features</NavLink></Link>
        <Link className="linkRoute" to="/Features"><NavLink variant="body2">Services</NavLink></Link>
        <Link className="linkRoute" to="/Features"><NavLink variant="body2">Listed</NavLink></Link>
        <Link className="linkRoute" to="/Features"><NavLink variant="body2">Contact</NavLink></Link>
      </NavbarLinksBox>
    </Box>
    <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem"}}> 
    <Link className="linkRoute" to="/register"><NavLink variant="body2">Sign Up</NavLink></Link>
      <Link className="linkRoute" to="/login">
      <CustomButton 
        backgroundColor="#0F1B4C"
        color="#fff"
        buttonText="Login" 
      />
      </Link>
    </Box>
  </NavbarContainer>;
};

export default Navbar;
